import React, { useState } from 'react';
import Slider from 'react-slick';
import LeftArrow from '../../../src/assets/left-arrow.svg';
import RightArrow from '../../../src/assets/right-arrow.svg';
import ReactLoading from 'react-loading';

const LakeCarousel = () => {
    const [isLoading, setIsLoading] = useState(false); // Assuming data loading is set to false
    const img = ["15","16","17","18","19","20","21","1","2","3","4","5","6","7","8","9","10","11","12","14"];

    const SlickArrowLeft = ({ ...props }) => (
        <img src={LeftArrow} alt="prevArrow" {...props} style={{ width: '50px', height: '30px' }} />
    );
    
    const SlickArrowRight = ({ ...props }) => (
        <img src={RightArrow} alt="nextArrow" {...props} style={{ width: '50px', height: '30px' }} />
    );
    

    const settings = {
        autoplay: true,                // Enable autoplay
        autoplaySpeed: 3000,          // Set autoplay speed (3 seconds)
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2, dots: true } },
            { breakpoint: 600, settings: { slidesToShow: 2 } },
            { breakpoint: 480, settings: { slidesToShow: 1 } },
        ],
    };

    return (
        <div className="flex justify-center items-center ">
            <div className="header bg-white self-center w-full max-w-[1170px] mt-1 pt-6 pb-9 px-10 rounded-xl max-md:px-5">
                {isLoading ? (
                    <div className="flex justify-center items-center h-[100vh]">
                        <ReactLoading color="blue" height="5%" width="5%" />
                    </div>
                ) : (
                    <Slider {...settings}>
                        {img.map((imgName, index) => (
                            <div key={index} className="flex items-stretch w-[350px] p-2 h-56 max-md:w-full">
                                <img src={`/img/lakechelan/${imgName}.jpg`} alt={`Lake Chelan ${imgName}`} />
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
            <style jsx>{`
                .arrow {
                    width: 30px;   /* Set the desired width for arrows */
                    height: 30px;  /* Set the desired height for arrows */
                }
            `}</style>
        </div>
    );
}

export default LakeCarousel;
